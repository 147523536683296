<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="editPixelStatusForm"
        ref="editPixelStatusForm"
        class="w-100 pt-6"
        @submit.prevent="submiteditPixelStatusForm"
      >
        <v-col cols="12">
          <v-progress-linear
            v-if="isProcessing"
            indeterminate
            absolute
            top
            height="3"
          />
          <v-autocomplete
            v-model="pixelStatus"
            :items="pixelStatuses"
            outlined
            label="Status pixel"
            item-text="label"
            item-value="value"
            placeholder="Wybierz status pixel"
            :rules="[rules.required]"
            append-icon="mdi-chevron-down"
            dense
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :disabled="isProcessing"
        :loading="isProcessing"
        type="submit"
        form="editPixelStatusForm"
      >
        Edytuj status
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data() {
    return {
      rules,
      pixelStatus: null
    }
  },
  created() {
    this.pixelStatus = this.orderTemplate.pixelStatus
  },
  computed: {
    ...mapState({
      isProcessing: state => state.orderTemplate.isProcessing,
      orderTemplate: state => state.layout.dialog.item
    }),
    pixelStatuses() {
      return [
        { label: 'Wprowadzono do systemu', value: 'created' },
        { label: 'Zabrane', value: 'picked' }
      ]
    }
  },
  methods: {
    ...mapActions({
      editPixelStatus: 'orderTemplate/editPixelStatus',
      closeDialog: 'layout/closeDialog'
    }),

    submiteditPixelStatusForm() {
      if (this.$refs.editPixelStatusForm.validate()) {
        const payload = {
          id: this.orderTemplate.id,
          pixelStatus: this.pixelStatus
        }
        this.editPixelStatus(payload).then(() => {
          this.closeDialog()
        })
      }
    }
  }
}
</script>
